.layout {
  min-height: 100vh;
  .title {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    line-height: 54px;
    &.collapsed {
      .logo {
        margin-left: 24px;
      }
      h3 {
        max-width: 0;
        opacity: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .logo {
      width: 32px;
      height: 32px;
      margin-left: 16px;
      background-image: url('~@/assets/logo.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transition: margin-left 0.3s ease-out;
    }
    h3 {
      margin: 0;
      padding-left: 8px;
      font-size: 16px;
      color: #fff;
      line-height: 32px;
      white-space: nowrap;
      transition: all 0.3s ease-out;
    }
  }
  .header {
    display: flex;
    justify-content: right;
    padding: 0;
    height: 48px;
    line-height: 48px;
    background-color: #fff;
    border-bottom: 1px solid #f0f2f5;
    box-shadow: 0 1px 2px #f0f2f5;
    z-index: 99;
    box-sizing: border-box;
  }
  .toolbar {
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
    > span {
      padding-right: 10px;
    }
    .dropdown {
      &:hover {
        .arrow {
          transform: rotate(180deg);
        }
      }
      .arrow {
        transition: all 0.3s linear;
      }
    }
    .avatar {
      vertical-align: middle;
      box-sizing: border-box;
    }
    .name {
      cursor: pointer;
      margin-left: 6px;
      margin-right: 6px;
    }
  }
  .footer {
    padding: 0;
  }
}

@menu-dark-bg: #272936;