:global {
  .ant-layout-sider-dark {
    z-index: 10;
    background-color: #272936;
    background-image: url('~@/assets/layout/siderbar_bg@2x.jpeg');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: contain;
    .ant-layout-sider-trigger {
      background-color: transparent;
    }
    .ant-menu-dark {
      background-color: transparent;
      &.ant-menu-dark:not(.ant-menu-horizontal) {
        .ant-menu-item-selected {
          position: relative;
          background-color: rgba(66, 172, 255, 0.2);
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
            background-color: #3d90ff;
          }
        }
      }
      .ant-menu-inline.ant-menu-sub {
        background-color: transparent;
      }
    }
    .ant-menu-sub {
      background-color: #272936;
    }
  }
  .ant-menu-dark {
    background-color: #272936;
    &.ant-menu-dark:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        background-color: rgba(66, 172, 255, 0.2);
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: #3d90ff;
        }
      }
    }
    .ant-menu-inline.ant-menu-sub {
      background-color: #272936;
    }
  }
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: #272936;
  }
}

@menu-dark-bg: #272936;